<template>
    <div class="mv-not-found w-50 mx-auto mt-5">
        <h2>
            404 -
            <small>Entschuldigung, hier haben wir uns wohl verklickt...</small>
        </h2>
        <p>
            {{ text }} Bitte wählen Sie über das Menü eine andere Seite aus oder
            wenden sich über unser
            <b-link href="#">Kontaktformular</b-link>&nbsp;an uns.
        </p>
    </div>
</template>
<script>
import "./NotFound.scss";
export default {
    name: "NotFound",
    props: ["text"]
};
</script>
