<template>
    <MainContainer>
        <NotFound
            text="Die aufgerufene Seite kann leider nicht gefunden werden."
        />
    </MainContainer>
</template>

<script>
import MainContainer from "@/components/MainContainer/MainContainer";
import NotFound from "@/components/NotFound/NotFound";
export default {
    name: "Error404",
    components: {
        MainContainer,
        NotFound
    }
};
</script>
